.mlogo{
  width:400px;
  height:auto;
}
.Frame-Container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.Frame-Content {
  /*
  display: flex;
  flex: 1;
  */
}

.Frame-Loading {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}
