@import '../../../utils/styles/colors.scss';

.GridView-Container {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.GridView-Top-Container {
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 2px 2px $lightBlue;
    margin-top: 10px;
    margin-bottom: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.GridView-SearchBar {
    display: flex;
    flex: 2.5; /*This should match the Filter Container flex property*/
}

.GridView-RightOptions {
    display: flex;
    flex: 4;
}

.GridView-Create-New:hover {
    cursor: pointer;
}

.GridView-Link {
    color: $turquoiseBlue;
    font-size: 13pt;
    font-family: 'Avenir-Heavy';
    margin-left: 5px;
    margin-right: 5px;
}

.GridView-Icon {
    color: $turquoiseBlue;
}

.GridView-Bottom-Container {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-right: 45px;
    padding-left: 25px;
}

.GridView-Header-Title {
    display: flex;
    flex: 1;
    font-family: Avenir-Heavy;
    font-size: 14px;
    color: $charcoalGrey;
}

.GridView-List {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 75vh;
}

.GridView-Errors {
    display: flex;
    flex: 4.5;
    list-style: none;
    color: red;
}

