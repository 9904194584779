$approxGainBoro: #dbdbdb;
$approxSnow: #fcfcfc;
$black: #000000;
$charcoalGrey: #363b3e;
$coolGrey: #9ba1a4;
$darkGreen: #2e3230;
$darkGrey: #a5a5a5;
$fadedGrey: #0000008a;
$greenishBeige: #cfd97f;
$light2Grey: #f6f6f6;
$lightBlue1: #d0e1e7;
$lightBlue3: #ecf9fc;
$lightBlue: #d0e1e7;
$lightGreen2: #cdf9fc;
$lightGreen: #d0e7e4;
$lightGrey2: #f6f6f6;
$lightGrey3: #ededed;
$lightGrey: #e8e8e8;
$lightMustardGreen: #b6bd0003;
$lightShakespeare: #bbd5df;
$mediumBlue: #009dc7;
$mediumBlueWithAlpha: #009dc780;
$mediumGrey: #7f7f73;
$mediumGreyWithAlpha2: #4a4a4a1a;
$mediumGreyWithAlpha3: #9d9d9d80;
$mediumGreyWithAlpha: #7f7f731a;
$mediumMustardGreen: #b6bd001a;
$mustardGreen: #b6bd00;
$offWhite: #f8f8f8;
$offWhiteBorder: #0000006b;
$orange: #ff8000;
$red: rgb(255, 0, 0);
$silverGrey: #d8d8d8;
$splitGreen: #228b22;
$suvaGrey: #939393;
$turquoiseBlue: #009dc7;
$veryLightPink: #e1e1e1;
$veryLightPinkTwo: #d8d8d8;
$veryLightPinkWithAlpha: #e1e1e11a;
$white: #ffffff;
$whiteThree: #f4f4f4;
