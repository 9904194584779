@import '../../../utils/styles/colors.scss';

.ConfirmDispatchModal-Header {
  border-bottom: 0px;
}

.ConfirmDispatchModal-Header-Container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.ConfirmDispatchModal-Title {
  display: flex;
  flex: 1;
  margin-top: 25px;
  align-self: center;
  font-family: 'Avenir-Light';
  font-size: 24px;
  text-align: center;
  color: $charcoalGrey;
}

.ConfirmDispatchModal-Body {
  margin-left: 35px;
  margin-right: 35px;
  justify-content: center;
  align-items: center;
}

.ConfirmDispatchModal-Body-Header {
  font-family: Avenir-Light;
  font-size: 24px;
  color: $charcoalGrey;
  text-align: center;
}

.ConfirmDispatchModal-Footer {
  display: flex;
  flex: 1;
  justify-content: center;
  border-top: 0px;
  margin-bottom: 25px;
}

.ConfirmDispatchModal-X-Button {
  display: flex;
  flex: 1;
  align-self: flex-end;
  color: $turquoiseBlue;
}

.ConfirmDispatchModal-Icon {
  height: 49px;
  width: 43px;
  align-self: center;
  margin-top: 30px;
}

.ConfirmDispatchModal-Attribute-Container {
  display: flex;
  margin-top: 25px;
  flex-direction: column;
  align-items: center;
}

.ConfirmDispatchModal-Attribute {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.ConfirmDispatchModal-Attribute-Title {
  font-family: Avenir-Heavy;
  font-size: 14px;
  margin-right: 3px;
  color: $darkGreen;
  text-transform: capitalize;
}

.ConfirmDispatchModal-Attribute-Value {
  font-family: Avenir-Light;
  font-size: 14px;
  color: $darkGreen;
}
