@import '../../../utils/styles/colors.scss';

.CustomButton-Default {
  font-family: 'Avenir-Heavy';
  font-size: 16px;
  color: $charcoalGrey;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  padding-top: 7px;
  padding-bottom: 5px;
  padding-right: 30px;
  padding-left: 30px;
  min-width: 140px;
}

.CustomButton-Default:hover {
  cursor: pointer;
}

.CustomButton-Bordered {
  background-color: white;
  border: 2px solid $mustardGreen;
}

.CustomButton-Plain {
  background-color: $mustardGreen;
  border: 2px solid $mustardGreen;
}

.CustomButton-Plain:disabled {
  background-color: $greenishBeige;
  color: $mediumGrey;
}

.CustomButton-Disabled {
  pointer-events: none;
}