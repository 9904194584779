@import '../../../../utils/styles/colors.scss';

.GridViewItem-Bottom-Container {
    display: flex;
    padding-right: 50px;
    padding-left: 25px;
}

.GridViewItem-Bottom-Container-With-Background {
    background-color: $mediumGreyWithAlpha;
}

.GridViewItem-List-Header {
    display: flex;
    flex: 1;
    font-family: Avenir-Medium;
    font-size: 14px;
    color: $charcoalGrey;
    padding-top: 10px;
    padding-bottom: 10px;
}

.GridViewItem-Edit-Container {
    display: flex;
    flex-direction: row;
}

.GridViewItem-Edit-Container:hover {
    cursor: pointer;
}

.GridViewItem-Link {
    color: $turquoiseBlue;
    font-size: 10pt;
    font-family: 'Avenir-Heavy';
    margin-left: 5px;
    margin-right: 5px;
}

.GridViewItem-Icon {
    font-size: 20px;
    color: $turquoiseBlue;
}

.GridViewItem-Edit-Container-Disable {
    display: flex;
    flex: 1;
    flex-direction: row;
    pointer-events: none;
}
