@import '../../../../utils/styles/colors.scss';

.NavBar-Container {
  box-shadow: 0px 1px 0px #0000000f;
  padding-bottom: 0;
}

.NavBar-ActiveTabIndicator {
  border-bottom: 3px solid $mustardGreen;
}

.NavBar-HiddenTabIndicator {
  border-bottom: 3px solid transparent;
}

.NavBar-TabIndicator {
  display: flex;
  align-items: center;
  align-self: stretch;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 16px;
}

.NavBar-linksContainer {
  justify-content: flex-end;
  align-items: center;
  background-color: white;
  align-self: stretch;
  flex: 1;
}

.NavBar-NotificationIcon {
  color: $darkGrey;
}

.NavBar-ProfileIconContainer {
  height: 36px;
  width: 36px;
  display: flex;
  border-radius: 18px;
  justify-content: center;
  align-content: center;
  background-color: $darkGrey;
}

.NavBar-ProfileIcon {
  color: $white;
  text-align: center;
  font-size: 20px;
  align-self: center;
}

//Items with overlay
.NavBar-Tooltip-Container {
  display: flex;
  align-items: center;
  align-self: stretch;
}

.NavBar-Tooltip {
  margin-top: 5px;
  background-color: #363b3e;
  padding: 2px 20px;
  color: $white;
  border-radius: 15px;
}

.NavBar-Dropdown-Item {
  display: block;
  width: 100%;
  padding: 0 1.5rem;
  clear: both;
  font-weight: 400;
  color: $charcoalGrey;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item.active, .dropdown-item:active {
  color: black;
  text-decoration: none;
  background-color: transparent;
}

.dropdown-menu {
    top: 110%;
    right: 0;
    left: auto;
}

.navbar-light .navbar-nav .nav-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.NavBar-Dropdown-Item:last-child{
  padding: 0 1.5rem;
}
.dropdown-item.hover, .dropdown-item:hover{
background:transparent;
}
.div-dropdown{
  padding-left:1.5rem;
  padding-right:1.5rem;
  padding-top:0.55rem;
  padding-bottom:0.55rem;
  margin:0 -24px;
}

.div-dropdown:hover{
  background:#f8f9fa;
}

/* RESPONSIVE */
@media only screen and (max-width: 1440px) {
  .NavBar-TabIndicator {
    font-size: 15px;
  }
}
