.I-Header-Container {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 2px rgb(208, 225, 231);
  margin-top: 10px;
  margin-bottom: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.I-Header-SearchBar {
  label{
    padding:0;
    margin:0;
  }
  .row{
    margin:0;
    padding:0;
  }
  .Button {
    font-family: 'Avenir-Heavy';
    font-size: 16px;
    color: #363b3e;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    padding-top: 7px;
    padding-bottom: 5px;
    padding-right: 30px;
    padding-left: 30px;
    min-width: 140px;
  }
}

.I-Customer {
  display: flex;
  justify-content: flex-start;
}
.Header-ListHeader{
  display: flex;
  justify-content: flex-start;
}
