
.Row {
  display: flex;
  flex-direction: row;
}

.Column {
  display: flex;
  flex-direction: column;
}

.BillsLading-Container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
  background-color: rgb(248, 248, 248);
  .TableContainer-root{

  }
  .Pager-pager{
    .PageSizeSelector-pageSizeSelector,
    .PageSizeSelector-inputRoot{
      font-size:14px;
      font-family: inherit !important;
    }
    .Pagination-pagination{
      .Pagination-rowsLabel{
        font-size:14px;
        font-family: inherit !important;
        .Pagination-text{
          span{
            font-family: inherit !important;
          }

        }
      }
    }
  }
}

.BillsLading-Body {
  display: flex;
  flex-direction: row;
}

.BillsLading-Filter {
  display: flex;
  flex: 2.5 1;
  height: 100%;
}

.BillsLading-List {
  /*
  display: flex;
  flex: 7.5;
  min-height: 85vh;
  */
}
.BillsLadingList {
  overflow-y: auto;
  /*
  display: flex;
  flex: 7.5 1;
  flex-direction: column;
   */
  height: 100%;
  padding-left:8px;
}

table{
  tbody{
    tr{
      &:nth-child {
        background: rgba(333,333,333,0.2) !important;
      }
      td{
        font-family: inherit !important;
      }
    }
  }
}
