@import '../../../utils/styles/colors.scss';

.NoResult-Container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.NoResult-First-Title-Primary {
    display: flex;
    font-family: 'Avenir-Heavy';
    font-size: 18px;
    color: $charcoalGrey;
    text-align: center;
}

.NoResult-Second-Title-Primary {
    display: flex;
    font-family: 'Avenir-Medium';
    font-size: 14px;
    color: $charcoalGrey;
    text-align: center;
}

.NoResult-First-Title-Secondary {
    display: flex;
    font-family: 'Avenir-Heavy';
    font-size: 25px;
    color: $charcoalGrey;
    text-align: center;
}

.NoResult-Second-Title-Seconday {
    display: flex;
    font-family: 'Avenir-Medium';
    font-size: 18px;
    color: $charcoalGrey;
    text-align: center;
}

.NoResult-Icon {
    font-size: 90px;
    color: $coolGrey;
    margin-bottom: 35px;
}
