@import '../../utils/styles/colors.scss';

.Dashboard-PurchaseOrders-Headings {
    font-weight: bold;
    font-size: large;
    border-bottom: 3px solid $mustardGreen;
}

.Logo {
 height: 25px;
 width: 25px;
}

.Dashboard-PurchaseOrdersContainer {
 align-items: start;
 width: 440px;
}

.PO-Table-Container{
  height: 200px;
  width: 438px;
  overflow-y: scroll;
}

.PO-Table {
  border-collapse: collapse;
  bottom  : 25px;
  position: relative;
  text-align: center;
  font-size: 12px;
  display: inline;
 }

 .PO-Table-Head  .PO-Table-Heading {
  position: sticky;
  top: 0;
  z-index: 1;
 }

 .PO-Table-Heading,
 .PO-Table-Values {
  text-align: center;
  border-bottom: 1px solid #ddd;
  color: #000000;
  font-weight: lighter;
  padding-top: 5px;
  font-size: 10.5px;
 }
 
 .PO-Table-Head .PO-Table-Heading {
  background-color: #b4b6b6;
  font-weight: bold;
  font-size: 13px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
 }

 .PO-Table-Rows:nth-child(even) {
  background-color: #dedbdb;
 }


  .Label-Class {
    font-weight: bold;
    font-size: 15px;
    margin-top: 8px;
  }

  .PO-Button-class {
    float: right;
    padding-top: 25px;
  }

  .PO-Dashboard-button {
    background-color: rgb(143, 146, 146);
    font-size: small;
    font-weight: bold;
    border-radius: 2px;
  }

  .Loading-Class {
    justify-content: center;
    position: relative;
    left: 200px;
    top:100px
  }

  .PO-Table-Body{
    position: relative;
    top: 26px;
  }