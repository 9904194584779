@import '../../utils/styles/colors.scss';

.Row {
    display: flex;
    flex-direction: row;
  }
  
  .Column {
    float: left;
    width: 50%;
    padding: 10px;
    height: 300px;
  }

.Dashboard-OpenInvoices-Data {
    text-align: right;
}

.OpenInvoices-Headings {
    font-weight: bold;
    font-size: large;
}
.Label-Key {
    font-weight: bold;
    font-size: smaller;
}

.Label-Value {
font-size: smaller;
}

.Dashboard-OpenInvoices-Container {
  background-color: white;
  border-radius: 40px;
  box-shadow: 0px 2px 2px rgb(208, 225, 231);
  margin-top: 10px;
  margin-bottom: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 3px solid $mustardGreen;
  width: 600px;
  height: 260px;
  }
  
  .Dashboard-OpenInvoices-SearchBar {
    label{
      padding:0;
      margin:0;
    }
    .row{
      margin:0;
      padding:0;
    }
    .Button {
      font-family: 'Avenir-Heavy';
      font-size: 16px;
      color: #363b3e;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      padding-top: 7px;
      padding-bottom: 5px;
      padding-right: 30px;
      padding-left: 30px;
      min-width: 140px;
    }
  }
  
  .Dashboard-Customer {
    display: flex;
    justify-content: flex-start;
  }
  .Header-ListHeader{
    display: flex;
    justify-content: flex-start;
  }
  
  .table {
    display: grid;
    grid-template-columns: max-content max-content;
    text-align: right;
    justify-content: center;
    position: relative;
    right: 50px;
    margin-top: 10px;
    font-size: smaller;
  }
  
   .table > *:nth-child(even)::before {
     content: " : ";
  }

  .OpenInvoices-Keys{
    font-weight: bold;
  }

  .Loading-Class {
    justify-content: center;
    position: relative;
    left: 250px;
    top:100px
  }

  .Chart-Class {
    border: 2px solid #e8e8e8;
    border-radius: 3px;
    margin-left: 50px;
    margin-top: 10px
  }

  .xaxislabel{
    position: relative;
    bottom: 115px;
    font-size: small;
    left: 280px;
  }

  .yaxislabel{
    position: relative;
    bottom: 160px;
    right: 50px;
    font-size: x-small;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
  }