@import '../../utils/styles/colors.scss';

.Row {
    display: flex;
    flex-direction: row;
  }
  
  .Column {
    float: left;
    width: 50%;
    padding: 10px;
    height: 300px;
  }

  .vertical_dotted_line
{
    border-left: 2px dashed black;
    height: 240px;
} 

.Details-Headings {
    font-weight: bold;
    font-size: large;
}

.Dashboard-Details-Container {
    background-color: white;
    border-radius: 40px;
    box-shadow: 0px 2px 2px rgb(208, 225, 231);
    margin-top: 10px;
    margin-bottom: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 3px solid $mustardGreen;
    width: 828px;
    height: 260px;
  }

  .Details-Table {
    display: grid;
    grid-template-columns: max-content max-content;
    font-size: smaller;
    float: right;
    position: relative;
    left: 50px;
    height: 200px;
  }
  
   .Details-Table > *:nth-child(odd)::after {
     content: ":\00a0";
  }

  .Details-Keys{
    font-weight: bold;
    text-align: right;
    font-size: 12px;
  }

  .Details-Values{
    font-weight: lighter;
    width: 200px;
    font-size: 12px;
    flex-grow: 2;
    overflow: auto;
  }
  
  .Label-Value {
    font-weight: bold;
  }

.Loading-Class {
  justify-content: center;
  position: relative;
  left: 400px;
  top:80px
}