@import '../../../utils/styles/colors.scss';

.modal-content {
    margin: auto;
    width: 85%;
}
.AddEditUserModal-Header {
    padding-left: 30px;
    border-bottom: 0px;
}

.AddEditUserModal-Header-Container {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.AddEditUserModal-Footer {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    padding-left: 150px;
    padding-right: 150px;
    border-top: 0px;
}

.AddEditUserModal-Title {
    display: flex;
    flex: 1;
    margin-top: 25px;
    align-self: flex-start;
    font-family: 'Avenir-Light';
    font-size: 24px;
    text-align: center;
    color: $charcoalGrey;
}

.AddEditUserModal-X-Button {
    display: flex;
    align-self: flex-end;
    color: $turquoiseBlue;
}

.AddEditUserModal-X-Button:hover {
    cursor: pointer;
}

.AddEditUserModal-Upper-TextField-Container {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
}

.AddEditUserModal-Down-TextField-Container {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
}

.AddEditDriverModal-Textfield {
    padding-top: 15px;
}

.AddEditUserModal-Dropdownfield{
    left: 8px;
}

.AddEditUserModal-Textfield {
    display: flex;
    flex: 1;
    padding-left: 30px;
    padding-right: 30px;
}

.AddEditUserModal-Textfield .Textfield-FormContainer .form-control:disabled {
    background-color: white;
}

.AddEditUserModal-HasAccess-Container {
    display: flex;
    padding-left: 30px;
    margin-top: 50px;
    margin-bottom: 50px;
    flex-direction: column;
}

.AddEditUserModal-HasAccess-Title {
    margin-bottom: 15px;
}

.AddEditUserModal-HasAccess-Radio-Container {
    display: flex;
    flex-direction: row;
}

.AddEditUserModal-Radio {
    margin-right: 60px;
}

.AddEditUserModal-Radio-Input {
    margin-right: 10px;
}

.AddEditUserModal-ErrorMessage {
    display: flex;
    flex: 1;
    align-self: center;
    font-family: 'Avenir-Light';
    font-size: 15px;
    text-align: center;
    color: red;
}

.AddEditUserModal-SpinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.User-Audit-Grid-Container {
    max-height: 300px;
    overflow-y: auto;
}

.User-Audit-Grid {
    display: flex;
    flex-direction: row;
}

.User-Audit-Grid-Header {
    background-color: $white;
    position: sticky;
    top: 0;
    width: 100%;
}

.User-Audit-Grid-Header-Item {
    width: 15%;
    border-bottom: 1px solid $lightGrey;
    font-family: Avenir-Heavy;
    font-size: 11pt;
    padding: 5px;
    word-wrap: break-word;
}

.User-Audit-Grid-Header-Item-Lg {
    width: 20%;
    border-bottom: 1px solid $lightGrey;
    font-family: Avenir-Heavy;
    font-size: 11pt;
    padding: 5px;
    word-wrap: break-word;
}

.User-Audit-Grid-Data {
    margin-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid $lightGrey;
}

.User-Audit-Grid-Item {
    width: 15%;
    font-family: Avenir-Book;
    font-size: 10pt;
    padding: 5px;
    word-wrap: break-word;
}

.User-Audit-Grid-Item-Lg {
    width: 20%;
    font-family: Avenir-Book;
    font-size: 10pt;
    padding: 5px;
    word-wrap: break-word;
}

.NotResults-Container {
    padding: 5px;
}