
.Row {
  display: flex;
  flex-direction: row;
}

.Column {
  display: flex;
  flex-direction: column;
}

.Reports-Container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
  background-color: rgb(248, 248, 248);
  .TableContainer-root{
    // height:100vh !important;
  }
}

.Reports-Body {
  display: flex;
  flex-direction: row;
}

.Reports-Filter {
  display: flex;
  flex: 2.5 1;
  height: 100%;
}

.Reports-List {
  display: flex;
  flex: 7.5;
  height: 100%;
  // min-height: 85vh;
}
.ReportsList {
  overflow-y: auto;
  display: flex;
  flex: 7.5 1;
  flex-direction: column;
  height: 100%;
  padding-left:8px;
}

table{
  tbody{
    tr{
      &:nth-child {
        background: rgba(333,333,333,0.2) !important;
      }
      td{
        font-family: inherit !important;
      }
    }
  }
}
