@import '../../../utils/styles/colors.scss';

.loginsection {
  margin: 0 auto !important;
  width: 680px;
}

.Login {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.Login-Container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: calc(100% - 150px);
  width: 100%;
  align-items: center;
}

.Login-Logo {
  margin-top: 90px;
}

.Login-Form {
  display: flex;
  flex-direction: column;
  margin-top: 75px;
  height: 243px;
  width: 731px;
  border-radius: 15px;
  background-color: $mediumMustardGreen;
  align-items: center;
  justify-content: center;
}

.Login-Form-Email {
  margin-top: 85px;
  width: 290px;
}

input {
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
  background-color: $lightMustardGreen;
  font: 400 14px Avenir;
  border-bottom: 1px solid $mediumGrey;
}

.Login-Form-Button {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 133px;
  border-radius: 20px;
  background-color: $greenishBeige;
  font-family: Avenir;
  font-weight: 900;
  line-height: 22px;
}

button {
  border: none;
}

button:focus {
  outline: none;
}

.Login-Footer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Login-Footer-Asset {
  display: flex;
  align-self: center;
  margin-bottom: -5px;
}

.Login-Footer-Top {
  min-height: 13px;
  max-height: 13px;
  background-color: $mustardGreen;
}

.Login-Footer-Bottom {
  height: 50px;
  background-color: $mediumGrey;
}

.email-not-exist-div {
  margin-top: 10px;
  height: 5px;
}

.email-not-exist-lable {
  color: red;
}