@import '../../../utils/styles/colors.scss';

.Searchable-Dropdown-Container {
    cursor: pointer;
    margin: 0 8px;
    overflow-x: visible;
    overflow-y: visible;
    position: relative;
}

.Searchable-Dropdown-Container .dropdown .btn-primary {
    background-color: inherit;
    border: none;
    border-color: $mediumGreyWithAlpha3;
    border-bottom: solid 1px $offWhiteBorder;
    border-radius: 0;
    color: $black;
    font-family: 'Avenir-Book', sans-serif;
    font-size: 16px;
    font-weight: 400;
    height: 24px;
    line-height: 1.5;
    min-width: 250px;
    padding: 0;
    text-align: left;
    vertical-align: top;
    white-space: nowrap;
    margin-top: 19px;
}

.Searchable-Dropdown-Container .dropdown .btn-primary::after {
    float: right;
    margin-right: 15px;
    margin-top: 5px;
}

.Searchable-Dropdown-Container .dropdown .btn-primary:focus,
.Searchable-Dropdown-Container .dropdown .btn-primary:hover {
    border-bottom: solid 2px black;
    box-shadow: none;
    outline: none;
}

.Searchable-Dropdown-Container .dropdown .dropdown-menu {
    height: "auto";
    margin-top: 5px;    
    min-width: 250px;
}

.Searchable-Dropdown-Label {
    color: $fadedGrey;
    font-size: 11px;
    margin: -5px 0;
}

.Searchable-Dropdown-InputContainer {
    display: flex;
}

.Searchable-Dropdown-Error {
    color: red;
    font-size: 9.5pt;
}

.Searchable-Dropdown-Container .dropdown .btn-primary:focus,
.Searchable-Dropdown-Container .dropdown .btn-primary:hover {
    border-bottom: solid 2px black;
    box-shadow: none;
    outline: none;
}

.Searchable-Dropdown-InputContainer.Searchable-Dropdown-Error-Border div input,
.Searchable-Dropdown-Error-Border .dropdown .btn-primary,
.Searchable-Dropdown-Error-Border .dropdown .btn-primary:focus,
.Searchable-Dropdown-Error-Border .dropdown .btn-primary:hover {
    border-bottom: solid 2px red;
}

.SearchableDropdown-EndIcon {
    cursor: auto;
    margin-top: 8px;
}
