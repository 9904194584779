.SearchBar-Container {
  display: flex;
  flex: 1;
  border: 0.2pt solid rgba(225,225,225, 1);
  box-shadow: inset 0px 1px 3px 0px rgba( 157, 157, 157, 0.5);
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 15px;
  margin-right: 15px;
  height: 42px;
  border-radius: 21px;
  align-items: center;
  background-color: white;
}

.SearchBar-Input {
  display: flex;
  flex: 1;
  margin-left: 10px;
  margin-right: 10px;
  border: 0px;
  outline: none;
  font-family: 'Roboto';
  font-style: italic;
  color: rgba(74, 74, 74, 1);
}

.SearchBar-Icon {
  font-size: 18px;
}