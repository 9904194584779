@import '../../utils/styles/colors.scss';

.Filter-Container {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 20px;
    background-color: white;
    box-shadow: 0px 1px 2px $lightShakespeare;
    border-radius: 10px;
}

.Filter-Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    padding-right: 16px;
    padding-left: 20px;
    padding-bottom: 10px;
    box-shadow: 0px 1px 1px $approxGainBoro;
    margin-bottom: 1px;
    background-color: $approxSnow;
}

.Filter-HeaderIconContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Filter-HeaderTitle {
    margin-left: 10px;
    font-family: 'Avenir-Medium';
    font-size: 18px;
}

.Filter-Loading {
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}

.Filter-SelectAll {
    font-family: 'Avenir-Medium';
    font-size: 14px;
    color: $turquoiseBlue;
    padding-left: 0;
}

.Filter-Download-Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-top: 10px;
    padding-right: 16px;
    padding-left: 20px;
    padding-bottom: 10px;
    background-color: $approxSnow;
}

.Filter-Download-Icon {
    margin-right: 5px;
    color: $turquoiseBlue;
}

.MapLegend {
    font-family: 'Avenir-Medium';
    font-size: 14px;
    padding-left: 0;
}
.MapLegend-Header {
    font-family: 'Avenir-Heavy';
    font-size: 17px;
    padding-left: 0;
}

.PurchaseOrders-Container{
    .Header-Container{

    }
}

.CustomToggle-Container {
    border-width: 0px;
    display: flex;
    flex: 1;
    flex-direction: column;
    &:hover{
        cursor: pointer;
    }
    .CustomToggle-Header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        .CustomToggle-Title {
            display: flex;
            margin-top: 15px;
            margin-left: 26px;
            font-family: Avenir-Heavy;
            font-size: 17px;
            .CustomToggle-Arrow {
                margin-right: 26px;
                margin-top: 15px;
            }
        }
    }
}

.Filter-Error-Required {
    border: 1px red solid;
}

.CustomToggle-TagsContainer {
    display: flex;
    flex-wrap: wrap;
    height: 20px;
    background-color: red;
}

.CustomToggle-Divider {
    height: 1px;
    margin-left: 12px;
    margin-right: 12px;
    /*background-color: $silverGrey;*/
}

.FilterItem-Card {
    border-width: 0px;
}

.FilterItem-Body {
    box-shadow: inset 0px 1px 1px rgba(192, 192, 192, 0.54);
    background-color:  rgb(252, 252, 252);
    padding-top: 0;
}

.FilterItem-BodyPadding{
    padding-top: 7px;
}

.FilterItem-SelectAll {
    font-family: 'Avenir-Medium';
    font-size: 14px;
    color: rgb(0, 157, 199);
    padding-left: 0;
}

.FilterItem-InputContainer {
    display: flex;
    flex: 1;
    border: 0.2pt solid rgba(225,225,225, 1);
    box-shadow: inset 0px 1px 3px 0px rgba( 157, 157, 157, 0.5);
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 5px;
    margin-right: 15px;
    height: 32px;
    border-radius: 21px;
    align-items: center;
    background-color: white;
}

.FilterItem-Input {
    display: flex;
    flex: 1;
    margin-left: 10px;
    margin-right: 10px;
    border: 0px;
    outline: none;
    font-family: 'Roboto';
    font-style: italic;
    color: rgba(74, 74, 74, 1);
}

body{
    &.hide-sidebar{
        #jobsFilterContainer {
            position:fixed;
            z-index: 99999;
            left:-700px;
            button.Filter-SelectAll{
                position: fixed;
                z-index: 99999;
                left: 14px;
                color:#fff;
                background: $turquoiseBlue;
                padding: 5px 8px;
                height: 36px;
                border-radius:5px;

            }
        }
        #GridContainer{
            &.col-md-9{
                flex: 0 0 100%;
                max-width: 100%;
                padding-right: 15px !important;
                padding-left: 8px !important;
            }
        }
    }
}

#BodyGrid{
    height:calc(100vh - 150px);
}

.TableContainer-root{
    height: calc(100vh - 250px);
}

.btn_clear{
    font-family: 'Avenir-Heavy';
    font-size: 14px;
    color: #363b3e;
    display: inline-block;
    padding:5px 10px;
    background: #ddd;
    border-radius: 25px;
}
.btn_apply{
    font-family: 'Avenir-Heavy';
    font-size: 14px;
    color: #363b3e;
    display: inline-block;
    padding:5px 10px;
    font-weight: 700;
    background: $mustardGreen;
    border-radius: 25px;
}

/*======================================================================
========================================================================
RESPONSIVE
========================================================================
======================================================================*/

/* Laptop */

@media (min-width: 978px) and (max-width: 1280px) {
    .NavBar-Container{
        .mlogo {
            width: 340px;
            height: auto;
        }
        .NavBar-TabIndicator {
            font-size: 14px;
            padding:0 5px;
        }
    }
    .BL-Header-SearchBar{
        .Button{
            font-size: 14px;
            padding:7px 15px 5px 15px;
        }
    }
    .FilterItem-Body{
        .SearchBar-Container {
            margin:0;
        }
        .pt-3{
            padding-top:5px !important;
        }
        .form-group{
            margin-bottom: 5px;
            label{
                font-size:14px;
                margin-bottom: 0px;
            }
        }
    }

}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 979px) {
    .NavBar-Container{
        .mlogo {
            width: 340px;
            height: auto;
        }
        .NavBar-TabIndicator {
            font-size: 14px;
            padding:0 5px;
        }
    }
    .BL-Header-SearchBar,
    .PO-Header-SearchBar,
    .I-Header-SearchBar{
        .Button{
            font-size: 14px !important;
            padding:7px 15px 5px 15px !important;
        }
        label{
            padding: 0;
            font-size:14px;
        }
        .col-md-3,
        .col-md-6{
            padding:0;
        }
    }
    .FilterItem-Card{
        .CustomToggle-Title{
            margin-left: 10px !important;
            font-size: 14px !important;
        }
    }
    .FilterItem-Body{
        padding:5px 10px;
        .SearchBar-Container {
            margin:0;
        }
        .pt-3{
            padding-top:5px !important;
        }
        .form-group{
            margin-bottom: 5px;
            label{
                font-size:14px;
                margin-bottom: 0px;
            }
        }
        .col-md-6{
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
    .NavBar-Container{
        .mlogo {
            width: 200px;
            height: auto;
        }
        .NavBar-TabIndicator {
            font-size: 14px;
            padding:0 5px;
        }
    }
    #jobsFilterContainer{
        &.pr-0{
            padding-right:15px !important;
        }
    }
    .BL-Header-SearchBar,
    .PO-Header-SearchBar,
    .I-Header-SearchBar{
        .Button{
            font-size: 14px !important;
            padding:7px 15px 5px 15px !important;
        }
        label{
            padding: 0;
            font-size:12px;
        }
        .col-md-3,
        .col-md-6{
            padding:0;
        }
        .col-md-8{
            padding:15px;
        }
    }
    .FilterItem-Body{
        padding:5px 10px;
        .SearchBar-Container {
            margin:0;
        }
        .pt-3{
            padding-top:5px !important;
        }
        .form-group{
            margin-bottom: 5px;
            label{
                font-size:14px;
                margin-bottom: 0px;
            }
        }
        .col-md-6{
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    body {
        &.hide-sidebar {
            #jobsFilterContainer {
                position: relative;
                z-index: 99999;
                left: unset;

                button.Filter-SelectAll {
                    position: relative;
                    z-index: 99999;
                    left: unset;
                    color: $turquoiseBlue;
                    background: unset;
                    padding: 0.375rem 0.75rem;
                    height: unset;
                    border-radius: 5px;

                }
                .collapse{
                    display: none;
                }
            }

            #GridContainer {
                &.col-md-9 {
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding-right: 15px !important;
                    padding-left: 8px !important;
                }
            }
        }
    }
}



