* {
  box-sizing: border-box;
}

html,
body {
  margin:0;
  padding:0;
  font-family: 'Avenir-Book', sans-serif;
}

#root {
  height: 100vh;
}

@font-face {
  font-family: 'Avenir';
  src: url(./assets/fonts/AvenirLTStd-Roman.woff) format('woff');
}

@font-face {
  font-family: 'Avenir-Book';
  src: url(./assets/fonts/AvenirLTStd-Book.woff) format('woff');
}

@font-face {
  font-family: 'Avenir-Heavy';
  src: url(./assets/fonts/AvenirLTStd-Heavy.woff) format('woff');
}

@font-face {
  font-family: 'Avenir-Medium';
  src: url(./assets/fonts/AvenirLTStd-Medium.woff) format('woff');
}

@font-face {
  font-family: 'Avenir-Light';
  src: url(./assets/fonts/AvenirLTStd-Light.woff) format('woff');
}

@font-face {
  font-family: 'Avenir-Roman';
  src: url(./assets/fonts/AvenirLTStd-Roman.woff) format('woff');
}