@import '../../../utils/styles/colors.scss';

.UsersItem-Bottom-Container {
  display: flex;
  padding-right: 50px;
  padding-left: 25px;
}

.UsersItem-Bottom-Container-With-Background {
  background-color: $mediumGreyWithAlpha
}

.UsersItem-List-Header {
  font-family: Avenir-Medium;
  font-size: 14px;
  color: $charcoalGrey;
  text-align: left;
  word-wrap: break-word;
  width: 12%;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 25px;
}
.UsersItem-List-Header-Mid{
  width: 15%;
}
.UsersItem-List-Header-Lg{
  width: 20%;
}

.UsersItem-Edit-Container {
  display: flex;
  flex: 0.75;
  flex-direction: row;
}

.UsersItem-Edit-Container:hover {
  cursor: pointer;
}

.UsersItem-Link {
  color: $turquoiseBlue;
  font-size: 10pt;
  font-family: 'Avenir-Heavy';
  margin-left: 5px;
  margin-right: 5px;
}

.UsersItem-Icon {
  font-size: 20px;
  color: $turquoiseBlue;
}

.UsersItem-Edit-Container-Disable {
  display: flex;
  flex: 0.75;
  flex-direction: row;
  pointer-events: none;
}