@import '../../utils/styles/colors.scss';

.Dashboard-Container {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: 15px;
    padding-right: 15px;
    background-color: rgb(248, 248, 248);
  }

