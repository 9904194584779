.Row {
  display: flex;
  flex-direction: row;
}

.Column {
  display: flex;
  flex-direction: column;
}

.PurchaseOrders-Container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
  background-color: rgb(248, 248, 248);

  .TableContainer-root {}

  .Pager-pager {

    .PageSizeSelector-pageSizeSelector,
    .PageSizeSelector-inputRoot {
      font-size: 14px;
      font-family: inherit !important;
    }

    .Pagination-pagination {
      .Pagination-rowsLabel {
        font-size: 14px;
        font-family: inherit !important;

        .Pagination-text {
          span {
            font-family: inherit !important;
          }

        }
      }
    }
  }
}

.MuiListItemText-primary {
  font-family: inherit !important;
}

.PurchaseOrders-Body {
  display: flex;
  flex-direction: row;
}

.PurchaseOrders-Filter {
  display: flex;
  flex: 2.5 1;
  height: 100%;
}

.PurchaseOrders-List {
  /*
  display: flex;
  flex: 7.5;
  min-height: 85vh;
  */
}

.PurchaseOrdersList {
  overflow-y: auto;
  /*
  display: flex;
  flex: 7.5 1;
  flex-direction: column;
   */
  height: 100%;
  padding-left: 8px;

  /*
  .css-apqrd9-MuiTableBody-root{
    tr{
      td{
        &:nth-child(1n){
          text-align: center;
        }
      }
    }
  }
 */
  td.rd {
    text-align: center;
    border-bottom: 1px solid rgba(224, 224, 224, 1);

    input {
      margin-top: 5px;
    }
  }
}

table {
  tbody {
    tr {
      &:nth-child {
        background: rgba(333, 333, 333, 0.2) !important;
      }

      td {
        font-family: inherit !important;
      }
    }
  }
}
