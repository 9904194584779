
.Row {
  display: flex;
  flex-direction: row;
}

.Column {
  display: flex;
  flex-direction: column;
}

.Maintenance-Container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
  color:#fff;
  height: 100vh;
  background: #109cc0;
  background: -moz-radial-gradient(center, ellipse cover,  #109cc0 0%, #0064b7 100%);
  background: -webkit-radial-gradient(center, ellipse cover,  #109cc0 0%,#0064b7 100%);
  background: radial-gradient(ellipse at center,  #109cc0 0%,#0064b7 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#109cc0', endColorstr='#0064b7',GradientType=1 );
}

.Maintenance-Body {
  display: flex;
  flex-direction: row;
  margin: 15px;
  height: 100%;
  h2{
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-weight: 900;
    text-transform: uppercase;
  }
  h3{
    font-size: 20px;
    margin-bottom:25px;
  }
  strong{
    font-weight: 900;
  }
  img{
    &.img-maintenance{
      margin:0 auto 25px auto;
      width:200px;
      height: auto;
      border-radius:100%;
      border:1px dashed #ddd;
    }
  }
}

body.under-maintenance{
  .Frame-Navigation{
    display: none;
  }
}
