.Row {
  display: flex;
  flex-direction: row;
}

.Column {
  display: flex;
  flex-direction: column;
}

.OpenStatementAcct-Container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
  background-color: rgb(248, 248, 248);

}

.OpenStatementAcct-Body {
  display: flex;
  flex-direction: row;
}

.OpenStatementAcct-Filter {
  display: flex;
  flex: 2.5 1;
  height: 100%;
}

.OpenStatementAcct-List {
  /*
  display: flex;
  flex: 7.5;
  min-height: 85vh;
  */
}

.OpenStatementAcctList {
  overflow-y: auto;
  /*
  display: flex;
  flex: 7.5 1;
  flex-direction: column;
   */
  height: 100%;
  padding-left: 8px;
}

.base-genricCss {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgb(224, 224, 224);
  text-align: left;
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
}

table {
  tbody {
    tr {
      &:nth-child {
        background: rgba(333, 333, 333, 0.2) !important;
      }

      td {
        font-family: inherit !important;
      }
    }
  }
}