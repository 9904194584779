@import '../../utils//styles/colors.scss';

.Dashboard-Links-Headings {
    font-weight: bold;
    font-size: large;
    border-bottom: 3px solid $mustardGreen;
}

.Logo {
 height: 40px;
 width: 40px;
}

.Logo1 {
    height: 50px;
    width: 50px;
   }
.Dashboard-LinksContainer {
 align-items: start;
}

.Label-Class {
    font-weight: bold;
    font-size: 15px;
    margin-top: 5px;
  }

  .DashboardLinks-button-class {
    background-color: rgb(248, 248, 248);
  }

  .DashboardLink-Label{
    font-weight: bold;
    font-size: 11px;
    align-items: center;
  }
  