@import '../../utils/styles/colors.scss';

.Row {
    display: flex;
    flex-direction: row;
    font-weight: bold;
  }
  
  .Column {
    display: flex;
    flex-direction: column;
  }  

.Dashboard-Header-Container {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 2px 2px rgb(208, 225, 231);
    margin-top: 10px;
    margin-bottom: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 3px solid $mustardGreen;
    height: 56px;
  }
  
  .Dashboard-Header-SearchBar {
    label{
      padding:0;
      margin:0;
    }
    .row{
      margin:0;
      padding:0;
      font-weight: bold;
    }
    .Button {
      font-family: 'Avenir-Heavy';
      font-size: 16px;
      color: #363b3e;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      padding-top: 7px;
      padding-bottom: 5px;
      padding-right: 30px;
      padding-left: 30px;
      min-width: 140px;
    }
  }
  
  .Dashboard-Customer {
    display: flex;
    justify-content: flex-start;
  }
  .Header-ListHeader{
    display: flex;
    justify-content: flex-start;
  }
  
  .Dropdown-Class {
    width: 300px;
    margin-left: 30px;
  }
  