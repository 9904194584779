@import '../../../utils/styles/colors.scss';

.Loading-Spinner {
    color: $mustardGreen;
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    // height: 100%;
    // width: 100%;
    // background-color: #6c757d1c;
}
