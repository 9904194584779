@import '../../../utils/styles/colors.scss';

.form-control {
    background-color: inherit;
}

.form-control:focus {
    background-color: inherit;
}

.form-control:disabled {
    background-color: inherit;
    opacity: .6;
}

.Textfield-FormContainer {
    display: flex;
    align-content: space-between;
    margin-top: 15px;
    margin-bottom: 8px;
    width: 100%;
}

.Textfield-FormContainer form {
    display: flex;
    width: 100%;
}

.Textfield-Validation {
    color: #363b3e;
    background-color: inherit;
    border-top: none;
    border-left: none;
    border-right: none;
    align-self: start;
    font-family: 'Avenir-Book', sans-serif;
}

.Textfield-Validation label {
    margin-bottom: 0;
    flex: 1 100%;
    padding-right: 0px;
}

.Textfield-InputTitle {
    background-color: inherit;
    color: $fadedGrey;
    font-family: 'Avenir-Book', sans-serif;
    font-size: 0.7rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
    padding: 0;
    border: 0;
}

.Textfield-Icon {
    background-color: inherit;
    border: none;
    padding: 0;
    width: 20%;
}

.Textfield-FormContainer .input-group-append {
    flex: 1 1 auto;
}

.Textfield-FormContainer .form-control {
    border-bottom: 1px solid grey;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    width: 100%;
    border-radius: 0;
    margin-top: 3px;
}

.Textfield-FormContainer .form-control:focus,
.Textfield-FormContainer .form-control:hover {
    border-bottom: 2px solid black;
    box-shadow: none;
    display: flex;
}

.Textfield-Invalid {
    color: red;
    flex: 1 100%;
    padding-bottom: 5px;
}

.Textfield-Valid {
    flex: 1 100%;
}

.Textfield-Error {
    color: red;
}

.Textfield-InputBox {
    padding: 4px 0;
}

.InputBox-Error.form-control:focus,
.InputBox-Error.form-control:hover,
.InputBox-Error.form-control {
    border-bottom: 2px solid red;
    color: red;
}

.invalid-feedback {
    border: none;
    color: red;
    display: inline-block;
    font-family: 'Avenir-Book', sans-serif;
    font-size: 9.5pt;
    font-weight: 400;
    margin-top: 0;
}

.Textfield-InputRow {
    display: flex;
    width: 100%;
}

.Textfield-InputRow {
    height: 41px;
}

.Textfield-InputBox2 {
    padding: 4px 0;
    display: inline-block;
    font-size: 1rem;
    font-weight: 400;
    margin-top: 0;
    color: #495057;
    width: 100%;
}

.InputBox-Error2 {
    color: red;
    border-bottom: 2px solid red;
}

.Textfield-Disabled{
    background-color: inherit;
    opacity: .6;
}

