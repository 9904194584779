@import '../../utils/styles/colors.scss';
.Row {
  display: flex;
  flex-direction: row;
}

.Column {
  display: flex;
  flex-direction: column;
}

.Contact-Container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 25px;
  padding-right: 25px;
  background-color: rgb(248, 248, 248);
  .TableContainer-root{
    height:100vh !important;
  }
}

.Contact-Body {
  display: flex;
  flex-direction: row;
}

.Contact-Filter {
  display: flex;
  flex: 2.5 1;
  height: 100%;
}

.Contact-List {
  display: flex;
  flex: 7.5;
  min-height: 85vh;
}
.ContactList {
  overflow-y: scroll;
  display: flex;
  flex: 7.5 1;
  flex-direction: column;
  height: 100%;
  padding-left:8px;
}

.ContactPageDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  border: 2px solid $lightGrey;
  box-shadow: 0 2px 4px 0 $lightBlue, 0 2px 2px 0 $lightBlue;
}

table{
  margin:25px 0 50px 0 !important;
  thead{
    font-weight: 900 !important;
  }
  tbody{
    tr{
      a{
        color:#209dbe;
      }
      &:nth-child {
        background: rgba(333,333,333,0.2) !important;
      }
      td{
        font-family: inherit !important;
      }
    }
  }
}

.ContactPageDetailsContainer {
  padding-left: 66px;
  padding-right: 31px;
  padding-top: 5px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.R-Header-Container {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 2px rgb(208, 225, 231);
  margin-top: 10px;
  margin-bottom: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.R-Header-SearchBar {
  label{
    padding:0;
    margin:0;
  }
}
  .row{
    margin:0;
    padding:0;
  }

  .Reason-Dropdown{
    padding-top: 8px;
  }

.ContactPageConfirmation-FileBrowser-Background {
  background-color: white;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border: 1.5px dashed;
  border-color: rgb(138, 135, 135);
  width: 700px;
  height: 50px;
  margin-left: 10px;
  margin-top: 20px;
}

.ContactPageConfirmation-FileBrowser-Background :hover {
  cursor: pointer;
}

.ContactPageConfirmation-FileBrowser-Label {
  display: block;
  padding: 0px;
  border: 1px;
  height: 0px;
  margin-left: 10px;
  margin-top: 12px;
  outline: none;
  font-family: 'Avenir-Medium';
  font-size: 16px;
  color: $charcoalGrey;
  background-color: white;
  box-shadow: inset 0px 1px 3px #00000080;
}

.ContactPageConfirmation-FileBrowser-Label :hover {
  cursor: pointer;
}

.ContactPageConfirmation-Upload-Button {
  font-family: 'Avenir-Heavy';
  font-size: 16px;
  color: $charcoalGrey;
  text-align: center;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  padding-top: 7px;
  padding-bottom: 5px;
  padding-right: 30px;
  padding-left: 30px;
  background-color: $mustardGreen;
  margin-left: 150px;
}

.ContactFileWarningMessage{
  color: orange;
}

.ContactPageConfirmation-Upload-Button :hover {
  cursor: pointer;
}
.ContactPageConfirmation-Upload-Button:disabled {
  background-color: $greenishBeige;
  color: $mediumGrey;
}

.ContactPageConfirmation-FileBrowser-Text {
  font-family: 'Avenir-Book';
  font-size: 10px;
  margin-top: 5px;
  margin-left: 5px;
  color: $charcoalGrey;
}

.ContactPageConfirmation-FileBrowser-Input {
  visibility: hidden;
}

.label-rem-red {
  font-size: small;
  color: red;
}

.label-rem {
  font-size: small;
}