@import '../../utils/styles/colors.scss';

.Users-Container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.Users-Top-Container {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 2px $lightBlue;
  margin-top: 10px;
  margin-bottom: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Users-SearchBar {
  display: flex;
  flex: 1.25;
  /*This should match the Filter Container flex property*/
}

.Users-RightOptions {
  display: flex;
  flex: 4;
}

.Users-Create-New-User:hover {
  cursor: pointer;
}

.Users-Link {
  color: $turquoiseBlue;
  font-size: 13pt;
  font-family: 'Avenir-Heavy';
  margin-left: 5px;
  margin-right: 5px;
}

.Users-Icon {
  color: $turquoiseBlue;
}

.Users-Bottom-Container {
  height: 75vh;
  overflow: auto;
}

.Users-Bottom-Header-Container{
  height: 50px;
  background-color: $white;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 50px;
  padding-left: 25px;
}

.Users-Header-Title {
  font-family: Avenir-Heavy;
  font-size: 14px;
  color: $charcoalGrey;
  text-align: left;
  word-wrap: break-word;
  width: 12%;
  padding-right: 25px;
}
.Users-Header-Title-Mid{
  width: 15%;
}
.Users-Header-Title-Lg{
  width: 20%;
}

.Users-List {
  display: flex;
  flex-direction: column;
} 

.Users-Errors {
  display: flex;
  flex: 4.5;
  list-style: none;
  color: red;
}

.SpinnerContainer {
  position: fixed;
  top: 50%;
  left: 50%;
}